import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { motion } from 'framer-motion';
import { ANIM } from '@constants';
import useStyles from './styles';
import { AnimationContext } from '../animations';

const ForXXX = ({ subtitle }) => {
  const classes = useStyles();
  const { subtitleStartsMoving } = useContext(AnimationContext);

  return (
    <Typography
      variant="h5"
      color="textSecondary"
      className={classes.subtitle}
    >
      <motion.span
        style={{ display: 'inline-block' }}
        animate={{
          opacity: subtitleStartsMoving ? 1 : 0,
          y: subtitleStartsMoving ? '0px' : '1em',
        }}
        transition={{
          duration: 0.8,
          ease: ANIM.easeInOutCubic,
        }}
      >
        { subtitle }
      </motion.span>
    </Typography>
  );
};

ForXXX.propTypes = {
  subtitle: PropTypes.string.isRequired,
};

export default ForXXX;
