import React, { useState, useEffect, useRef } from 'react';
import { watchedWeMakeThingsAnimation } from '@store/actions';
import { useDispatch } from 'react-redux';

export const AnimationContext = React.createContext({
  hasAnimation: false,
  isStarted: false,
  weMakeThingsStartsMoving: false,
  forStartsMoving: false,
  subtitleStartsMoving: false,
  isReachedEnd: false,
});

export const useTimelineAnimation = () => {
  const [context, updateContext] = useState({
    hasAnimation: true,
    isStarted: false,
    weMakeThingsStartsMoving: false,
    forStartsMoving: false,
    subtitleStartsMoving: false,
    isReachedEnd: false,
  });
  const timerRefs = useRef([]);
  const dispatch = useDispatch();

  const startAnimation = () => {
    if (!context.isStarted) {
      updateContext((prevContext) => ({ ...prevContext, isStarted: true }));
      timerRefs.current.push(setTimeout(() => {
        updateContext((prevContext) => ({ ...prevContext, weMakeThingsStartsMoving: true }));
      }, 2000));
      timerRefs.current.push(setTimeout(() => {
        updateContext((prevContext) => ({ ...prevContext, forStartsMoving: true }));
      }, 2300));
      timerRefs.current.push(setTimeout(() => {
        updateContext((prevContext) => ({ ...prevContext, subtitleStartsMoving: true }));
      }, 2600));
      timerRefs.current.push(setTimeout(() => {
        updateContext((prevContext) => ({ ...prevContext, isReachedEnd: true }));
        dispatch(watchedWeMakeThingsAnimation());
      }, 3400));
    }
  };

  const stopAnimation = () => {
    updateContext({
      hasAnimation: false,
      isStarted: true,
      weMakeThingsStartsMoving: true,
      forStartsMoving: true,
      subtitleStartsMoving: true,
      isReachedEnd: true,
    });
  };

  const completeAnimation = () => {
    updateContext({
      hasAnimation: true,
      isStarted: true,
      weMakeThingsStartsMoving: true,
      forStartsMoving: true,
      subtitleStartsMoving: true,
      isReachedEnd: true,
    });
  };

  // clear timers on unmout
  useEffect(() => () => timerRefs.current.map(clearTimeout), []);

  return {
    AnimationContext,
    context,
    startAnimation,
    stopAnimation,
    completeAnimation,
  };
};
