import React from 'react';
import PropTypes from 'prop-types';
import useBreakpoints from '@utils/useBreakpoints';
import GatsbyBackgroundImage from 'gatsby-background-image';
import { motion } from 'framer-motion';
import useAnimationContext from '@utils/useAnimationContext';
import useStyles from './styles';
import { variants } from './animations';
import useData from './data';

const AppealingBackground = ({
  imageAtLeft,
  onLoad,
}) => {
  const classes = useStyles();
  const { smDown } = useBreakpoints();
  const data = useData();
  const { enabled: hasAnimation, triggered: animationTriggered } = useAnimationContext();

  return (
    <motion.div
      animate={!hasAnimation || animationTriggered ? 'visible' : 'hidden'}
      variants={variants}
      className={classes.backgroundBox}
    >
      <GatsbyBackgroundImage
        fluid={smDown
          ? data.bgMobile.childImageSharp.fluid
          : imageAtLeft
            ? data.bgRight.childImageSharp.fluid
            : data.bgLeft.childImageSharp.fluid}
        style={{ height: '100%' }}
        onLoad={onLoad}
      />
    </motion.div>
  );
};

AppealingBackground.defaultProps = {
  imageAtLeft: false,
  onLoad: () => {},
};

AppealingBackground.propTypes = {
  imageAtLeft: PropTypes.bool,
  onLoad: PropTypes.func,
};

export default AppealingBackground;
