import { makeStyles } from '@material-ui/core/styles';
import { STYLES } from '@constants';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  imageContainer: {
    position: 'relative',
    height: '100%',
  },
  maskContainer: {
    zIndex: 100,
    ...STYLES.absFit,
    width: '100%',
    height: '100%',
  },
  whiteMask: {
    position: 'absolute',
    zIndex: 100,
    top: 0,
    left: (props) => (props.revealDirection === 'right' ? 'auto' : 0),
    right: (props) => (props.revealDirection === 'left' ? 'auto' : 0),
    bottom: 0,
    height: '100%',
    width: '100%',
    background: 'white',
  },
  colorMask: {
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: (props) => (props.revealDirection === 'right' ? 'auto' : 0),
    right: (props) => (props.revealDirection === 'left' ? 'auto' : 0),
    bottom: 0,
    height: '100%',
    width: '100%',
    background: (props) => props.background,
  },
}));

export default useStyles;
