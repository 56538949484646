import { makeStyles } from '@material-ui/core/styles';
import { STYLES } from '@constants';

const useStyles = makeStyles((theme) => ({
  svg: {
    ...STYLES.absFit,
  },
  tagline: {
    dominantBaseline: 'hanging',
    fontFamily: '"CoreCircus", verdana, sans-serif',
    fontSize: '3rem',
    fontWeight: 'normal',
    lineHeight: '4rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '4rem',
      lineHeight: '5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '5rem',
      lineHeight: '6rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '7.2rem',
      lineHeight: '8rem',
    },
  },
}));

export default useStyles;
