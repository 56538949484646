import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import useAnimationContext from '@utils/useAnimationContext';
import { ANIM } from '@constants';
import useStyles from './styles';

const ScaleUp = ({ children, delay, display, className, style }) => {
  const classes = useStyles({ display });
  const { enabled: hasAnimation, triggered: animationTriggered } = useAnimationContext();

  return (
    <motion.div
      className={`${classes.wrapper} ${className}`}
      style={style}
      initial={{ scale: 0 }}
      animate={{
        scale: !hasAnimation || animationTriggered ? 1 : 0,
      }}
      transition={{
        delay,
        ease: ANIM.easeInOutBack,
        duration: '0.4',
      }}
    >
      { children }
    </motion.div>
  );
};

ScaleUp.defaultProps = {
  delay: 0,
  display: 'inline-block',
  className: '',
  style: {},
};

ScaleUp.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
  display: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line
};

export default ScaleUp;
