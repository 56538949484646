import { makeStyles } from '@material-ui/core/styles';
import { STYLES } from '@constants';

const useStyles = makeStyles(() => ({
  root: {
    ...STYLES.absFit,
  },
  heroVideo: {
    minWidth: '100%',
    minHeight: '100%',
    '@media (max-width: 1760px)': {
      height: '100%',
    },
  },
}));

export default useStyles;
