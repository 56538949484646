import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textContainer: {
    width: '40%',
    // overflow: 'hidden',
    padding: '0 8rem',
    zIndex: '100',
    [theme.breakpoints.down('md')]: {
      padding: '0 7rem',
    },
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0 2.5rem 5rem',
    },
  },
  textContainerInner: {
    [theme.breakpoints.down('sm')]: {
      marginTop: (props) => `${-5 * (props.headingLines / 2)}rem`,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: (props) => `${-4 * (props.headingLines / 2)}rem`,
    },
  },
  headingWrapper: {
    marginBottom: '4rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '3rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '2rem',
      width: '50%',
    },
  },
  text: {
    marginBottom: '5rem',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('md')]: {
      marginBottom: '4rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '3rem',
    },
  },
  buttonContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}));

export default useStyles;
