import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    maxHeight: '1080px',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      maxHeight: '810px',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '576px',
    },
  },
  textContainer: {
    position: 'absolute',
  },
}));

export default useStyles;
