import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  imageWrapper: {
    width: '60%',
    padding: '100px 0',
    zIndex: '100',
    [theme.breakpoints.down('md')]: {
      padding: '80px 0',
    },
    [theme.breakpoints.down('sm')]: {
      zIndex: '50',
      padding: '0',
      width: '100%',
    },
  },
}));

export default useStyles;
