import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import CTAButton from '@components/common/CTAButton';
import FadeSlideUp from '@components/common/FadeSlideUp';
import useBreakpoints from '@utils/useBreakpoints';
import useStyles from './styles';

const AppealingText = ({
  heading,
  text,
  buttonLabel,
  buttonWidth,
  linkTo,
  noButton,
}) => {
  const { smDown, mdDown } = useBreakpoints();
  const moreButtonWidth = mdDown ? '200px' : '220px';
  const headingWords = heading.split(' ');
  const classes = useStyles({ headingLines: headingWords.length });
  const stagger = 0.1;

  return (
    <div className={classes.textContainer}>
      <div className={classes.textContainerInner}>
        <div className={classes.headingWrapper}>
          <Typography variant="h1" component="h2">
            {headingWords.map((word, index) => (
              <FadeSlideUp
                display="block"
                delay={index * stagger}
                offset="40px"
                key={word}
              >
                { word }
              </FadeSlideUp>
            ))}
          </Typography>
        </div>
        <Typography variant="body2" className={classes.text}>
          <FadeSlideUp
            delay={(headingWords.length + 1) * stagger}
            offset="40px"
          >
            { text }
          </FadeSlideUp>
        </Typography>
        {noButton ? null : (
          <div className={classes.buttonContainer}>
            <FadeSlideUp
              delay={(headingWords.length + 3) * stagger}
              display="block"
              offset="40px"
            >
              <CTAButton
                to={linkTo}
                width={buttonWidth || moreButtonWidth}
                noLine={smDown}
                label={buttonLabel}
                delay={(headingWords.length + 3) * stagger + 0.6}
              />
            </FadeSlideUp>
          </div>
        )}
      </div>
    </div>
  );
};

AppealingText.defaultProps = {
  buttonLabel: 'MORE',
  buttonWidth: '',
  linkTo: '',
  noButton: false,
};

AppealingText.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  buttonWidth: PropTypes.string,
  linkTo: PropTypes.string,
  noButton: PropTypes.bool,
};

export default AppealingText;
