import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Wires = ({ wires }) => {
  const classes = useStyles();

  return (
    <>
      {wires.map((wire) => (
        <img
          src={wire.src}
          key={wire.src}
          alt="wire"
          className={`${classes.wire}`}
          style={{
            left: wire.side === 'left' ? 0 : 'auto',
            right: wire.side === 'right' ? 0 : 'auto',
            top: typeof wire.top === 'number' ? `${wire.top}%` : 'auto',
            bottom: typeof wire.bottom === 'number' ? `${wire.bottom}%` : 'auto',
          }}
        />
      ))}
    </>
  );
};

Wires.propTypes = {
  wires: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string.isRequired,
    side: PropTypes.oneOf(['left', 'right']),
    top: PropTypes.number,
    bottom: PropTypes.number,
  }).isRequired).isRequired,
};

export default Wires;
