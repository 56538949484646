import { makeStyles } from '@material-ui/core/styles';
import { STYLES } from '@constants';

const useStyles = makeStyles((theme) => ({
  backgroundBox: {
    position: 'absolute',
    bottom: '0',
    width: '60%',
    height: '33.75vw',
    maxHeight: '648px',
    [theme.breakpoints.down('sm')]: {
      ...STYLES.absFit,
      width: '100%',
      height: '100%',
      maxHeight: 'none',
    },
  },
}));

export default useStyles;
