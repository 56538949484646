import React from 'react';
import PropTypes from 'prop-types';
import useMeasure from '@utils/useMeasure';
import useStyles from './styles';

const HeroBackgroundVideo = ({ src, mimeType }) => {
  const classes = useStyles();
  const [{ ref: containerRef }, { width: containerWidth }] = useMeasure();
  const [{ ref: videoRef }, { width: videoWidth }] = useMeasure();

  return (
    <div ref={containerRef} className={classes.root}>
      <video
        ref={videoRef}
        autoPlay
        muted
        loop
        playsInline
        preload="auto"
        className={classes.heroVideo}
        style={{ transform: `translateX(${(containerWidth - videoWidth) / 2}px)` }}
      >
        <source src={src} type={mimeType} />
      </video>
    </div>
  );
};

HeroBackgroundVideo.defaultProps = {
  mimeType: 'video/mp4',
};

HeroBackgroundVideo.propTypes = {
  src: PropTypes.string.isRequired,
  mimeType: PropTypes.string,
};

export default HeroBackgroundVideo;
