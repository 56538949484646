import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { ANIM } from '@constants';
import useAnimationContext from '@utils/useAnimationContext';
import useStyles from './styles';
import { maskVariants, imageVariants } from './animations';

const ImageRevealer = ({
  revealDirection,
  background,
  delay,
  children,
  style,
}) => {
  const classes = useStyles({ revealDirection, background });
  const { enabled: hasAnimation, triggered: animationTriggered } = useAnimationContext();

  return (
    <div className={classes.root} style={style}>
      <motion.div
        className={classes.imageContainer}
        initial={!hasAnimation || animationTriggered ? 'visible' : 'hidden'}
        animate={!hasAnimation || animationTriggered ? 'visible' : 'hidden'}
        variants={imageVariants(delay)}
      >
        { children }
      </motion.div>
      <motion.div
        className={classes.maskContainer}
        initial={!hasAnimation || animationTriggered ? 'revealed' : 'visible'}
        animate={!hasAnimation || animationTriggered ? 'revealed' : 'visible'}
      >
        <motion.div
          className={classes.whiteMask}
          variants={maskVariants(delay, 0)}
        />
        <motion.div
          className={classes.colorMask}
          variants={maskVariants(delay, ANIM.ImageRevealer.mask.stagger)}
        />
      </motion.div>
    </div>
  );
};

ImageRevealer.defaultProps = {
  delay: 0,
  style: {},
};

ImageRevealer.propTypes = {
  revealDirection: PropTypes.oneOf(['left', 'right']).isRequired,
  background: PropTypes.string.isRequired,
  delay: PropTypes.number,
  children: PropTypes.node.isRequired,
  style: PropTypes.object, // eslint-disable-line
};

export default ImageRevealer;
