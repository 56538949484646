import { ANIM } from '@constants';

export const maskVariants = (delay, stagger) => ({
  visible: { height: '100%', width: '100%' },
  revealed: {
    height: '100%',
    width: 0,
    transition: {
      duration: ANIM.ImageRevealer.mask.duration,
      ease: ANIM.ImageRevealer.mask.ease,
      delay: delay + stagger,
    },
  },
});

export const imageVariants = (delay) => ({
  hidden: { scale: 1.3 },
  visible: {
    scale: 1.0,
    transition: {
      duration: ANIM.ImageRevealer.image.duration,
      ease: ANIM.ImageRevealer.image.ease,
      delay: delay + ANIM.ImageRevealer.mask.stagger,
    },
  },
});
