import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useBreakpoints from '@utils/useBreakpoints';
import FadeSlideUp from '@components/common/FadeSlideUp';
import ScaleUp from '@components/common/ScaleUp';
import useStyles from './styles';

const CapabilityItem = ({ title, skills, icon, delay, index }) => {
  const classes = useStyles({ icon });
  const { smDown } = useBreakpoints();

  const mobileSkillString = (
    <Typography variant="body2" align="center">
      {skills.join(' / ')}
    </Typography>
  );

  const desktopSkillList = (
    <FadeSlideUp offset="40px" delay={delay + 0.3}>
      <ul>
        {skills.map((skill) => (
          <Typography
            variant="body2"
            component="li"
            key={skill}
            align="center"
          >
            { skill }
          </Typography>
        ))}
      </ul>
    </FadeSlideUp>
  );

  return (
    <div className={classes.root}>
      <div className={classes.titleWrapper}>
        <ScaleUp delay={delay + index * 0.2} className={classes.iconWrapper}>
          <img
            src={icon}
            alt="icon"
            className={classes.icon}
          />
        </ScaleUp>
        <Typography variant="h4" className={classes.title}>
          <FadeSlideUp offset="40px" delay={delay}>
            { title }
          </FadeSlideUp>
        </Typography>
      </div>
      <div>
        {smDown
          ? mobileSkillString
          : desktopSkillList}
      </div>
    </div>
  );
};

CapabilityItem.defaultProps = {
  delay: 0,
  index: 0,
};

CapabilityItem.propTypes = {
  title: PropTypes.string.isRequired,
  skills: PropTypes.arrayOf(PropTypes.string).isRequired,
  icon: PropTypes.string.isRequired,
  delay: PropTypes.number,
  index: PropTypes.number,
};

export default CapabilityItem;
