import { useStaticQuery, graphql } from 'gatsby';

const useData = () => useStaticQuery(graphql`
  query {
    bgLeft: file(relativePath: {eq: "appealing-bg-left.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1152) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgRight: file(relativePath: {eq: "appealing-bg-right.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1152) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgMobile: file(relativePath: {eq: "appealing-bg-mobile.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`);

export default useData;
