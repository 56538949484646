import React from 'react';
import PropTypes from 'prop-types';
import useBreakpoints from '@utils/useBreakpoints';
import { Grid } from '@material-ui/core';
import CenteredSectionContainer from '@components/common/CenteredSectionContainer';
import SectionTitle from '@components/common/SectionTitle';
import IdeaIcon from '@images/capabilities-icon-idea.svg';
import DesignIcon from '@images/capabilities-icon-design.svg';
import FabricationIcon from '@images/capabilities-icon-fabrication.svg';
import TechIcon from '@images/capabilities-icon-tech.svg';
import useInViewAnimation from '@utils/useInViewAnimation';
import CapabilityItem from './CapabilityItem';

const CapabilitiesSection = ({ ideation, design, fablication, tech }) => {
  const { smDown } = useBreakpoints();
  const {
    context,
    AnimationContext,
    intersectionRef,
  } = useInViewAnimation({ threshold: 0.5 });

  return (
    <AnimationContext.Provider value={context}>
      <div ref={intersectionRef}>
        <CenteredSectionContainer
          paddingXl="0 70px"
          paddingSm="0 30px"
          paddingXs="0 25px"
          maxWidthXl="1300px"
        >
          <SectionTitle>
            CAPABILITIES
          </SectionTitle>
          <Grid
            container
            spacing={smDown ? 4 : 6}
          >
            <Grid item xs={12} md={3}>
              <CapabilityItem
                title="Ideation"
                skills={ideation}
                icon={IdeaIcon}
                delay={0.2}
                index={0}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CapabilityItem
                title="Design"
                skills={design}
                icon={DesignIcon}
                delay={0.2}
                index={1}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CapabilityItem
                title="Fabrication"
                skills={fablication}
                icon={FabricationIcon}
                delay={0.2}
                index={2}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CapabilityItem
                title="Tech"
                skills={tech}
                icon={TechIcon}
                delay={0.2}
                index={3}
              />
            </Grid>
          </Grid>
        </CenteredSectionContainer>
      </div>
    </AnimationContext.Provider>
  );
};

CapabilitiesSection.propTypes = {
  ideation: PropTypes.arrayOf(PropTypes.string).isRequired,
  design: PropTypes.arrayOf(PropTypes.string).isRequired,
  fablication: PropTypes.arrayOf(PropTypes.string).isRequired,
  tech: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CapabilitiesSection;
