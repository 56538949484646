import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    maxWidth: '720px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '640px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '540px',
    },
  },
}));

export default useStyles;
