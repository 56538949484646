import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import useBreakpoints from '@utils/useBreakpoints';
import { ANIM } from '@constants';
import { useMediaQuery } from '@material-ui/core';
import { AnimationContext } from '../animations';
import useStyles from './styles';

const WeMakeThings = ({
  viewWidth,
  viewHeight,
  textContainerTop,
  onResize,
}) => {
  const classes = useStyles();
  const { mdDown, smDown, xsDown } = useBreakpoints();
  const {
    isStarted,
    weMakeThingsStartsMoving,
    isReachedEnd,
  } = useContext(AnimationContext);
  const tooSmallToShowTextInOneLine = useMediaQuery('(max-width:410px)');

  const getX = () => {
    const contentMaxWidth = mdDown ? 800 : 1000;
    const gapLeft = (viewWidth - contentMaxWidth) / 2;
    const minGapLeft = xsDown ? 25 : smDown ? 30 : 112;
    return Math.max(gapLeft, minGapLeft);
  };
  const getTextHeight = () => {
    if (tooSmallToShowTextInOneLine) return 40 * 2;
    if (xsDown) return 40;
    if (smDown) return 50;
    if (mdDown) return 60;
    return 80;
  };
  const restingY = textContainerTop - getTextHeight();

  // when window is resized, let the parent component update the height
  useEffect(
    () => onResize(getTextHeight()),
    [xsDown, smDown, mdDown, tooSmallToShowTextInOneLine],
  );

  return (
    <svg
      width="100%"
      height="100%"
      viewBox={`0 0 ${viewWidth} ${viewHeight}`}
      className={classes.svg}
    >
      <defs>
        <mask id="mask">
          <motion.rect
            id="alpha"
            x="0"
            y="0"
            width="100%"
            height="100%"
            initial={{ fill: 'rgba(255, 255, 255, 1.0)' }}
            animate={{
              fill: weMakeThingsStartsMoving ? 'rgba(0, 0, 0, 1.0)' : 'rgba(255, 255, 255, 1.0)',
            }}
            transition={{
              duration: isReachedEnd ? 0 : 1.0,
              ease: 'easeInOut',
            }}
          />
          <motion.text
            x={getX()}
            className={classes.tagline}
            initial={{ y: '50%' }}
            animate={{ y: weMakeThingsStartsMoving ? `${restingY}px` : '50%' }}
            transition={{
              duration: isReachedEnd ? 0 : 1.0,
              ease: ANIM.easeInOutCubic,
            }}
          >
            {tooSmallToShowTextInOneLine ? (
              <>
                <tspan fill="rgba(255, 255, 255, 1.0)">
                  We Make
                </tspan>
                <tspan x={getX()} dy="1.4em" fill="rgba(255, 255, 255, 1.0)">
                  Things
                </tspan>
              </>
            ) : (
              <motion.tspan
                initial={{ fill: 'rgba(255, 255, 255, 1.0)' }}
                animate={{
                  fill: !isStarted || weMakeThingsStartsMoving
                    ? 'rgba(255, 255, 255, 1.0)' : 'rgba(0, 0, 0, 1.0)',
                }}
                transition={{
                  duration: isReachedEnd ? 0 : weMakeThingsStartsMoving ? 0.8 : 0.2,
                  ease: 'easeInOut',
                }}
              >
                We Make Things
              </motion.tspan>
            )}
          </motion.text>
        </mask>
      </defs>
      <rect
        id="base"
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill="rgba(255, 255, 255)"
        mask="url(#mask)"
      />
    </svg>
  );
};

WeMakeThings.defaultProps = {
  onResize: () => {},
};

WeMakeThings.propTypes = {
  viewWidth: PropTypes.number.isRequired,
  viewHeight: PropTypes.number.isRequired,
  textContainerTop: PropTypes.number.isRequired,
  onResize: PropTypes.func,
};

export default WeMakeThings;
