import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  for: {
    marginBottom: '1.8rem',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '1.0rem',
    },
  },
}));

export default useStyles;
