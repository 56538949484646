import React, { useRef, useEffect, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { ANIM } from '@constants';
import useMeasure from '@utils/useMeasure';
import useBreakpoints from '@utils/useBreakpoints';
import useStyles from './styles';
import { AnimationContext } from '../../animations';

const RotationalText = ({ items }) => {
  const classes = useStyles();
  const [bind, { width: containerWidth, height: containerHeight }] = useMeasure();
  const { smUp, mdUp, lgUp } = useBreakpoints();
  const adjustedY = mdUp ? -3 : -4;
  const timerRefs = useRef([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { subtitleStartsMoving } = useContext(AnimationContext);

  const longestItem = useMemo(() => {
    let longest = items[0];
    items.forEach((item) => {
      if (item.length > longest.length) longest = item;
    });
    return longest;
  }, [items]);

  // start loop when animationTriggered flag becomes true
  useEffect(() => {
    const loopInterval = 2000;
    if (subtitleStartsMoving) {
      timerRefs.current.push(setInterval(() => {
        // show next item
        setCurrentIndex(
          (prevIndex) => ((prevIndex >= items.length - 1) ? 0 : prevIndex + 1),
        );
      }, loopInterval));
    }
  }, [subtitleStartsMoving]);

  // clear timers on unmout
  useEffect(() => () => timerRefs.current.map(clearTimeout), []);

  const textDy = lgUp ? '61px' : mdUp ? '43px' : smUp ? '34px' : '26px';

  return (/* eslint-disable-next-line react/jsx-props-no-spreading */
    <div className={classes.root} {...bind}>
      <span style={{ visibility: 'hidden' }}>
        { longestItem }
      </span>
      <div className={classes.container}>
        <AnimatePresence>
          <motion.div
            key={currentIndex}
            className={classes.inner}
            initial={{ y: '1em' }}
            animate={{ y: 0 }}
            exit={{ y: '-1em' }}
            transition={{
              duration: 0.4,
              ease: ANIM.easeInOutQuad,
            }}
          >
            <svg
              width="100%"
              height="100%"
              viewBox={`0 ${adjustedY} ${containerWidth} ${containerHeight}`}
              className={classes.svg}
            >
              <text className={classes.text} dy={textDy}>
                { items[currentIndex] }
              </text>
            </svg>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

RotationalText.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RotationalText;
