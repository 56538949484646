import { makeStyles } from '@material-ui/core/styles';
import { STYLES } from '@constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleWrapper: {
    width: '100%',
    height: '120px',
    position: 'relative',
    marginBottom: '30px',
    [theme.breakpoints.down('md')]: {
      height: '100px',
      marginBottom: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '80px',
    },
  },
  iconWrapper: {
    width: '100%',
    height: '100%',
  },
  icon: {
    ...STYLES.absCenter,
    width: '100%',
    height: '100%',
  },
  title: {
    ...STYLES.absCenter,
    zIndex: '100',
  },
}));

export default useStyles;
