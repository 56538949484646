import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wire: {
    position: 'absolute',
    pointerEvents: 'none',
    zIndex: 9999,
    width: '33%',
    [theme.breakpoints.down('md')]: {
      width: '40%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      zIndex: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
}));

export default useStyles;
