import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    flexDirection: (props) => (props.imageAtLeft ? 'row-reverse' : 'row'),
    [theme.breakpoints.down('sm')]: {
      flexDirection: () => 'column-reverse',
    },
  },
}));

export default useStyles;
