import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import useMeasure from '@utils/useMeasure';
import CenteredSectionContainer from '@components/common/CenteredSectionContainer';
import { useSelector } from 'react-redux';
import useBreakpoints from '@utils/useBreakpoints';
import { detect } from 'detect-browser';
import { Typography } from '@material-ui/core';
import WeMakeThings from './WeMakeThings';
import ForXXX from './ForXXX';
import Subtitle from './Subtitle';
import { useTimelineAnimation } from './animations';
import HeroBackgroundVideo from './HeroBackgroundVideo';
import useStyles from './styles';

const HeroVideoSection = ({ weMakeThingsFor, subtitle, videoUrl }) => {
  const classes = useStyles();
  const seen = useSelector((state) => state.haveWatchedWeMakeThingsAnimation);
  const { xsDown } = useBreakpoints();
  const browser = useRef();
  const supported = (browser.current && browser.current.name === 'chrome');
  const useSVGAnimation = !xsDown && supported;
  const {
    AnimationContext,
    context,
    startAnimation,
    stopAnimation,
    completeAnimation,
  } = useTimelineAnimation();
  const [bindRoot, { width: svgContainerWidth, height: svgContainerHeight }] = useMeasure();
  const [bindTextContainer, { height: textContainerHeight }] = useMeasure();
  const [weMakeThingsHeight, setWeMakeThingsHeight] = useState(0);
  const totalTextHeight = weMakeThingsHeight + textContainerHeight;

  const onResize = useCallback((height) => {
    setWeMakeThingsHeight(height);
  }, [setWeMakeThingsHeight]);

  // no animation for mobile
  useEffect(() => {
    if (xsDown) stopAnimation();
  }, [xsDown]);

  // start animation on mount
  useEffect(() => {
    browser.current = detect();
    const isChrome = (browser.current && browser.current.name === 'chrome');
    const skipAnimation = xsDown || seen || !isChrome;
    if (!skipAnimation) startAnimation();
    else completeAnimation();
  }, []);

  const textContainerTop = ((svgContainerHeight - totalTextHeight) / 2) + weMakeThingsHeight;

  return (
    <AnimationContext.Provider value={context}>
      { /* eslint-disable-next-line react/jsx-props-no-spreading */ }
      <section className={classes.root} {...bindRoot}>
        <HeroBackgroundVideo
          src={videoUrl}
          type="video/mp4"
        />
        {useSVGAnimation ? (
          <WeMakeThings
            viewWidth={svgContainerWidth}
            viewHeight={svgContainerHeight}
            textContainerTop={textContainerTop}
            onResize={onResize}
          />
        ) : null}
        <CenteredSectionContainer style={{ height: '100%', position: 'absolute' }}>
          <div
            className={classes.textContainer}
            style={{
              top: `${textContainerTop}px`,
              visibility: (textContainerTop === 0) ? 'hidden' : 'visible',
            }}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...bindTextContainer}
          >
            {useSVGAnimation ? null : (
              <Typography variant="h1" color="textSecondary">
                WE MAKE THINGS
              </Typography>
            )}
            <ForXXX
              weMakeThingsFor={weMakeThingsFor}
              cutoutText={useSVGAnimation}
            />
            <Subtitle
              subtitle={subtitle}
            />
          </div>
        </CenteredSectionContainer>
      </section>
    </AnimationContext.Provider>
  );
};

HeroVideoSection.propTypes = {
  weMakeThingsFor: PropTypes.arrayOf(PropTypes.string).isRequired,
  subtitle: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
};

export default HeroVideoSection;
