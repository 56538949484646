import React from 'react';
import PropTypes from 'prop-types';
import Layout from '@layouts/default';
import SEO from '@components/meta/SEO';
import { graphql } from 'gatsby';
import Wires from '@components/common/Wires';
import FirstWire from '@images/wire-home-1.png';
import SecondWire from '@images/wire-home-2.png';
import ThirdWire from '@images/wire-home-3.png';
import HeroVideoSection from '@components/home/HeroVideoSection';
import SectionSpacer from '@components/common/SectionSpacer';
import CapabilitiesSection from '@components/home/CapabilitiesSection';
import AppealingPointSection from '@components/common/AppealingPointSection';
import FeaturedProjectsSection from '@components/common/FeaturedProjectsSection';
import ContactBannerSection from '@components/common/ContactBannerSection';

const IndexPage = ({ data }) => {
  const homePage = data.homePage.edges[0].node;

  return (
    <Layout>
      <SEO title="Home" />
      <Wires
        wires={[
          {
            src: FirstWire,
            side: 'right',
            top: 30,
          },
          {
            src: SecondWire,
            side: 'left',
            top: 50,
          },
          {
            src: ThirdWire,
            side: 'right',
            top: 70,
          },
        ]}
      />
      <HeroVideoSection
        weMakeThingsFor={homePage.weMakeThingsFor}
        subtitle={homePage.heroSubtitle.heroSubtitle}
        videoUrl={homePage.heroVideo.file.url}
      />
      <SectionSpacer />
      <CapabilitiesSection
        ideation={homePage.capabilitiesIdeationList}
        design={homePage.capabilitiesDesignList}
        fablication={homePage.capabilitiesFablicationList}
        tech={homePage.capabilitiesTechList}
      />
      <SectionSpacer />
      <SectionSpacer sm="0" xs="0" />
      <AppealingPointSection
        heading={homePage.projectsIntroSectionHeading}
        text={homePage.projectsIntroSectionText.projectsIntroSectionText}
        image={homePage.projectsIntroSectionImage.fluid}
        imageAlt={homePage.projectsIntroSectionImage.title}
        linkTo="/projects"
      />
      <SectionSpacer sm="0" xs="0" />
      <SectionSpacer sm="0" xs="0" />
      <AppealingPointSection
        imageAtLeft
        heading={homePage.productsIntroSectionHeading}
        text={homePage.productsIntroSectionText.productsIntroSectionText}
        image={homePage.productsIntroSectionImage.fluid}
        imageAlt={homePage.productsIntroSectionImage.title}
        linkTo="/products"
      />
      <SectionSpacer sm="0" xs="0" />
      <FeaturedProjectsSection />
      <ContactBannerSection />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line
  location: PropTypes.object.isRequired, // eslint-disable-line
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    homePage: allContentfulHomePage(limit: 1) {
      edges {
        node {
          id
          weMakeThingsFor
          heroSubtitle {
            heroSubtitle
          }
          heroVideo {
            file {
              url
              fileName
            }
          }
          capabilitiesIdeationList
          capabilitiesDesignList
          capabilitiesFablicationList
          capabilitiesTechList
          projectsIntroSectionHeading
          projectsIntroSectionText {
            projectsIntroSectionText
          }
          projectsIntroSectionImage {
            title
            fluid(maxWidth: 1152) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          productsIntroSectionHeading
          productsIntroSectionText {
            productsIntroSectionText
          }
          productsIntroSectionImage {
            title
            fluid(maxWidth: 1152) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
