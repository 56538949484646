import { makeStyles } from '@material-ui/core/styles';
import { STYLES } from '@constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    position: 'relative',
  },
  container: {
    display: 'inline-block',
    ...STYLES.absFit,
    overflow: 'hidden',
  },
  inner: {
    display: 'inline-block',
    ...STYLES.absFit,
  },
  svg: {
    ...STYLES.absFit,
  },
  text: {
    fill: 'transparent',
    stroke: 'white',
    strokeWidth: 2,
    [theme.breakpoints.down('sm')]: {
      strokeWidth: 1,
    },
  },
}));

export default useStyles;
