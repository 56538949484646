import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { motion } from 'framer-motion';
import { ANIM } from '@constants';
import useStyles from './styles';
import RotationalText from './RotationalText';
import { AnimationContext } from '../animations';

const ForXXX = ({ weMakeThingsFor }) => {
  const classes = useStyles();
  const { forStartsMoving } = useContext(AnimationContext);

  return (
    <Typography
      variant="h1"
      color="textSecondary"
      className={classes.for}
    >
      <motion.span
        style={{ display: 'inline-block' }}
        animate={{
          opacity: (forStartsMoving) ? 1 : 0,
          y: (forStartsMoving) ? '0px' : '1em',
        }}
        transition={{
          duration: 0.8,
          ease: ANIM.easeInOutCubic,
        }}
      >
        For&nbsp;
        <RotationalText
          items={weMakeThingsFor}
        />
      </motion.span>
    </Typography>
  );
};

ForXXX.propTypes = {
  weMakeThingsFor: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ForXXX;
