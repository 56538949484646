import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import useInViewAnimation from '@utils/useInViewAnimation';
import AppealingText from './AppealingText';
import AppealingImage from './AppealingImage';
import AppealingBackground from './AppealingBackground';
import useStyles from './styles';

const AppealingPointSection = ({
  imageAtLeft,
  heading,
  text,
  image,
  imageAlt,
  buttonLabel,
  buttonWidth,
  linkTo,
  noButton,
}) => {
  const classes = useStyles({ imageAtLeft });
  const {
    context,
    AnimationContext,
    intersectionRef,
    trigger: triggerAnimation,
  } = useInViewAnimation({ threshold: 0.5, waitUntilManuallyTriggered: true });
  const [isImageLoaded, setImageLoaded] = useState(false);
  const [isBGImageLoaded, setBGImageLoaded] = useState(false);

  useEffect(() => {
    if (isImageLoaded && isBGImageLoaded) triggerAnimation();
  }, [isImageLoaded, isBGImageLoaded, triggerAnimation]);

  return (
    <AnimationContext.Provider value={context}>
      <section
        ref={intersectionRef}
        className={classes.root}
      >
        <AppealingText
          heading={heading}
          text={text}
          buttonLabel={buttonLabel}
          buttonWidth={buttonWidth}
          linkTo={linkTo}
          noButton={noButton}
        />
        <AppealingImage
          image={image}
          imageAlt={imageAlt}
          imageAtLeft={imageAtLeft}
          onLoad={() => setImageLoaded(true)}
        />
        <AppealingBackground
          imageAtLeft={imageAtLeft}
          onLoad={() => setBGImageLoaded(true)}
        />
      </section>
    </AnimationContext.Provider>
  );
};

AppealingPointSection.defaultProps = {
  imageAtLeft: false,
  imageAlt: 'appealing point image',
  buttonLabel: 'MORE',
  buttonWidth: '',
  linkTo: '',
  noButton: false,
};

AppealingPointSection.propTypes = {
  imageAtLeft: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: GatsbyImagePropTypes.fluid.isRequired,
  imageAlt: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonWidth: PropTypes.string,
  linkTo: PropTypes.string,
  noButton: PropTypes.bool,
};

export default AppealingPointSection;
