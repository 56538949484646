import React from 'react';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import ImageRevealer from '@components/common/ImageRevealer';
import useStyles from './styles';

const AppealingImage = ({
  image,
  imageAlt,
  imageAtLeft,
  delay,
  onLoad,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.imageWrapper}>
      <ImageRevealer
        revealDirection={imageAtLeft ? 'left' : 'right'}
        background="linear-gradient(90deg, #FFE5FA 0%, #C3F1FF 100%)"
        delay={delay}
      >
        <GatsbyImage
          fluid={image}
          alt={imageAlt}
          onLoad={onLoad}
        />
      </ImageRevealer>
    </div>
  );
};

AppealingImage.defaultProps = {
  imageAlt: 'appealing point image',
  imageAtLeft: false,
  delay: 0,
  onLoad: () => {},
};
AppealingImage.propTypes = {
  image: GatsbyImagePropTypes.fluid.isRequired,
  imageAlt: PropTypes.string,
  imageAtLeft: PropTypes.bool,
  delay: PropTypes.number,
  onLoad: PropTypes.func,
};

export default AppealingImage;
